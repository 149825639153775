<template>
    <section class="h-100 custom-scroll overflow-auto">
        <div class="row mx-0 h-100 bg-view" :style="`background-image: url(${ _.isEmpty(imagenesGenerales[indice]) ? '/img/no-imagenes/sin_imagen.png' : imagenesGenerales[indice]});`">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 column-buttons pr-1 ml-auto">
                <div class="row mx-0 justify-center mt-1 mb-1">
                    <div class="col-xl-auto col-lg-auto col-md-auto col-sm-12 px-0 cr-pointer" >
                        <div class="p-3 align-items-end position-relative" @click="$router.push({name: 'registro'})" >
                            <img :src="imgCliente.inicio.registro ? imgCliente.inicio.registro : '/img/no-imagenes/soy_nuevo.svg'" class="br-12 obj-fit img-right" width="100%" height="100%" style="left:0px" />
                        </div>
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-1 mb-1">
                    <div class="col-xl-auto col-lg-auto col-md-auto col-sm-12 px-0 cr-pointer">
                        <div class="p-2 position-relative" @click="$router.push({name: 'ingreso'})">
                            <img :src="imgCliente.inicio.ingreso ? imgCliente.inicio.ingreso : '/img/no-imagenes/tengo_una_cuenta.svg'" class="br-12 obj-fit img-right" width="100%" height="100%" style="left:0px" />
                        </div>
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-1 mb-1" @click="dondeTeEncuentras()">
                    <div class="col-xl-auto col-lg-auto col-md-auto col-sm-12 px-0 cr-pointer">
                        <div class="p-2 position-relative">
                            <img :src="imgCliente.inicio.invitado ? imgCliente.inicio.invitado : '/img/no-imagenes/invitado.svg'" class="br-12 obj-fit img-right" width="100%" height="100%" style="left:0px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-ubicacion ref="modalUbicacion" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    layout: 'auth',
    components: {
        modalUbicacion: () => import('./partials/modalUbicacion')
    },
    data(){
        return {
            indice: 0
        }
    },
    computed: {
        ...mapGetters({
            imgCliente :'x_parametros/imgCliente',
        }),
        imagenesGenerales(){
            return this.imgCliente.ingreso;
        }
    },
    mounted(){
        setInterval(() => {
            let cantidad = this.imagenesGenerales.length - 1
            this.indice++;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);
    },
    methods: {
        dondeTeEncuentras(){
            this.$refs.modalUbicacion.toggle('asignar');
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-view{
    background-repeat:no-repeat;
    background-size:cover;
    transition: all 1s ease-out !important;
}
.img-right{
    @media (max-width: 1366px) {
       // width: 400px !important;
        height: 150px !important;
    }
     @media (min-width: 1400px) {
       // width: 400px !important;
        height: 200px !important;
    }
}
.column-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .purple-button{
        background-image: linear-gradient(to top, #CDB1FF, #A880FF, #7D4AE8);
        cursor: pointer;
        width: 312px;
        height: 147px;
        border-radius: 12px;
        position: relative;
        display: flex;
        align-items: flex-end;
        @media (min-width: 300px) and (max-width: 700px) {
            width: 400px !important;
        }
        p{
            line-height: 24px;
            color: #FFFFFF;
        }
        img{
            position: absolute;
            bottom: 0;
            object-fit: cover;
            right: 10px;
        }
    }
    .green-button{
        background-image: linear-gradient(to top, #B1F2FF, #80FFB7, #0DEC81);
        cursor: pointer;
        width: 312px;
        height: 147px;
        border-radius: 12px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        @media (min-width: 300px) and (max-width: 1366px) {
            width: 400px !important;
            height: 400px !important;
        }
        
        p{
            line-height: 24px;
            text-align: right;
            line-height: 18px;
            color: #FFFFFF;
        }
        img{
            position: absolute;
            bottom: 0;
            object-fit: cover;
            left: 10px;
        }
    }
    .blue-button{
        background-image: linear-gradient(to top, #B1EAFF, #80E9FF, #4AC8E8);
        cursor: pointer;
        width: 312px;
        height: 147px;
        border-radius: 12px;
        position: relative;
        display: flex;
        align-items: flex-end;
        @media (min-width: 300px) and (max-width: 700px) {
            width: 400px !important;
        }
        p{
            line-height: 24px;
            color: #FFFFFF;
        }
        img{
            position: absolute;
            bottom: 0;
            object-fit: cover;
            right: 10px;
        }
    }
}


.background-column{
    /* background-image: url('/img/login/wm_inicio.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1s ease-out !important;

    .imagen-select{
        display: none !important;
        max-width: 100%;
        //width: 50vh;
        height: 50vh;
        object-fit: cover;
    }

    @media (min-width: 300px) and (max-width: 1270px) {
        background-size: contain;
        background-position: left;
    }

    @media (min-width: 300px) and (max-width: 700px) {
        display: none;
    }
}

.column-text{
    .text-column{
        /* top: 190px;
        right: -25px; */
        .superior-text{
            font-size:27px;
        }
        .digital-text{
            line-height: 67px;
            font-size:4vw;
        }
        @media (min-width: 300px) and (max-width: 1240px) {
            display: none !important;
    
        }
    }
    @media (min-width: 300px) and (max-width: 1240px) {
        display: none !important;
    }
}


</style>