<template>
    <section class="bg-fondo d-flex a-center j-center" style="height:calc(100vh - 75px)">
        <div class="box d-flex flex-column">
            <ValidationObserver ref="validacion">
                <p class="title text-center pt-5">
                    Nueva <br /> contraseña
                </p>
                <div class="row mx-0 j-center mt-4">
                    <div class="col-9 mb-4">
                        <small class="pl-3 text-general">
                            Nueva Contraseña
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|min:6|max:120" vid="password" name="Nueva contraseña">
                            <el-input ref="password" v-model="nueva_pass" placeholder="Nueva contraseña" show-password class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-9 mb-4">
                        <small class="pl-3 text-general">
                            Confirmar Contraseña
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|max:120|confirmed:password" name="Confirmar Contraseña">
                            <el-input v-model="rep_pass" placeholder="Repetir contraseña" show-password class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-9">
                        <div class="btn-general f-18 br-8" style="height:44px;" @click="save">
                            Guardar e ingresar
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <!-- Partials -->
        <modal-error-pass ref="modalError" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '~/services/auth';
const VUEX_RUTA = 'auth'

export default {
    components: {
        modalErrorPass: () => import('./partials/modalErrorPass')
    },
    data(){
        return {
            nueva_pass: '',
            rep_pass: '',
        }
    },
    computed: {
        ...mapGetters({
            datosUsuario: `${VUEX_RUTA}/obtenerDatosUsuarioRegistro`,
        }),
    },
    methods: {
        async save(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;

                const model = {
                    password: this.nueva_pass,
                    telefono: this.datosUsuario.telefono,
                    correo: this.datosUsuario.correo
                };
                const {data} = await Service.postNuevaContrasena(model)

                if(!data.exito){
                    return this.notificacion('Mensaje', 'Ocurrio un error al intentar guardar contraseña', 'warning');
                }

                this.$store.commit('auth/ALMACENAR_TOKEN', data.token);
                this.$store.commit('auth/ALMACENAR_USUARIO', data.usuario);

                if(!data.tienda){
                    return this.$router.push({ name: 'tienda.agregar' });
                }

                this.$router.push({ name: 'home' });
            }catch(e){
                this.errorCatch(e)
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.box{
    width: 597px;
    height: 55vh;
    border-radius: 12px;
    box-shadow: 0px 1px 4px #00000014;
    .title{
        font-size: 37px !important;
        font-weight: 600;
    }
}

@media (min-height: 300px) and (max-height: 780px) {
    .box{
        height: 69vh !important;
    }
}
@media (min-width: 300px) and (max-width: 695px) {
    .box{
        height: calc(100% - 1px) !important;
        width: 100%;
        border-radius: inherit !important;
    }
}
</style>