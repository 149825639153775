<template>
    <section class="bg-fondo d-flex a-center justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-center justify-content-center bg-view" :style="`background-image:url(${imgVerificacion[indice]})`">
        <div class="box d-flex flex-column mr-xl-3 mr-lg-3 mr-md-2 mr-sm-2 mr-2">
            <div id="sign-in-button" />
            <p class="title text-center pt-5">
                Verificar Código
            </p>
            <div class="row mx-0 j-center">
                <div class="col-8">
                    <div v-if="errorCodigo" class="position-absolute alert-message bg-white br-12">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            Código incorrecto
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            El código ingresado no es válido<br />
                        </p>
                    </div>
                    <p class="text-general f-15 mt-3">
                        Te enviamos un código al 
                    </p>
                    <p class="f-17">
                        <span class="f-500">+{{ datosUsuario.indicativo }}</span>
                        <span class="text-green ml-2">{{ datosUsuario.telefono }}</span>
                    </p>
                </div>
            </div>
            <div class="row mx-0 mt-4 j-center">
                <div class="button-digits mx-1">
                    <el-input ref="cod1" v-model.number="value1" class="w-100" maxlength="1" @input.self="next(2)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod2" v-model.number="value2" class="w-100" maxlength="1" @input.self="next(3)" @keyup.native.delete="back(1)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod3" v-model.number="value3" class="w-100" maxlength="1" @input.self="next(4)" @keyup.native.delete="back(2)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod4" v-model.number="value4" class="w-100" maxlength="1" @input.self="next(5)" @keyup.native.delete="back(3)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod5" v-model.number="value5" class="w-100" maxlength="1" @input.self="next(6)" @keyup.native.delete="back(4)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod6" v-model.number="value6" class="w-100" maxlength="1" @keyup.native.delete="back(5)" />
                </div>
            </div>
            <div class="row mx-0 j-center my-2">
                <p class="col-8 text-general f-300 f-15 cr-pointer">
                    ¿No te llegó nada? <span class="text-green f-500 ml-3" @click="reenviarCodigo">Reenviar código</span> 
                </p>
            </div>
            <div class="row mx-0 j-center">
                <div class="col-8 px-0">
                    <div class="btn-general br-8" style="height:44px;" @click="validarCodigo">
                        Ingresar
                    </div>
                </div>
            </div>
        </div>
        <div class="col-auto px-xl-4 px-lg-4 px-md-3 px-sm-3 px-2" /> 
        <!-- Partials -->
        <modal-error-pass ref="modalError" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Registro from '~/services/registro'
import Service from '~/services/auth';

const VUEX_RUTA = 'auth'

export default {
    components: {
        modalErrorPass: () => import('./partials/modalErrorPass')
    },
    data(){
        return {
            nuevoCodigo: false,
            errorCodigo: false,
            value1: '',
            value2: '',
            value3: '',
            value4: '',
            value5: '',
            value6: '',
            indice: 0
        }
    },
    computed: {
        ...mapGetters({
            datosUsuario: `${VUEX_RUTA}/obtenerDatosUsuarioRegistro`,
            imgCliente :'x_parametros/imgCliente',
        }),
        imgVerificacion(){
            return this.imgCliente.verificacion;
        },
        codigoCompleto(){
            return `${this.value1}${this.value2}${this.value3}${this.value4}${this.value5}${this.value6}`
        },
        validarCodigoCompleto(){
            return [this.value1,this.value2, this.value3, this.value4, this.value5, this.value6].every((el) => {
                return !_.isEmpty(`${el}`.trim())
            })
        },
    },
    mounted(){
        this.$store.commit('home/setHomeCache',false)
        setInterval(() => {
            let cantidad = this.imgVerificacion.length - 1
            this.indice++;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);
    },
    methods: {
        async validarCodigo(){
            try {
                if(this.validarCodigoCompleto){
                    const data = await Registro.verificarCodigo(this.codigoCompleto)
                    if(!data){
                        return this.errorCodigo = true;
                    }
                    this.loguearUsuario();
                }
            } catch(e){
                this.errorCatch(e)
            }
        },

        async loguearUsuario(){
            try {
                const model = {
                    usuario: this.datosUsuario.telefono
                };

                const {data} = await Service.iniciarSesionNumeroCelular(model)
                if(!data.exito){
                    return this.notificacion('Mensaje', 'Ocurrio un error al intentar iniciar sesión', 'warning');
                }

                this.$store.commit('auth/ALMACENAR_TOKEN', data.token);
                this.$store.commit('auth/ALMACENAR_USUARIO', data.usuario);

                this.$router.push({name:'home'});
            } catch(e){
                this.errorCatch(e)
            }
        },

        async reenviarCodigo(){
            try {
                this.limpiarErrores();
                const numeroCelular = `${this.datosRegistro.indicador}${this.datosRegistro.telefono}`;
                const data = await Registro.enviarCodigoRegistro(numeroCelular);
                if(data){
                    this.nuevoCodigo = true;
                    this.limpiarCodigo();
                }
            } catch(e){
                this.errorCatch(e)
            } finally {
                setTimeout(() => {
                    this.nuevoCodigo = false;
                }, 5000)
            }
        },

        next(number){
            this.$nextTick(function(){
                if(this.$refs[`cod${number - 1}`].value != "" && number > 1){
                    this.$refs[`cod${number}`].focus()
                }
            })
        },

        back(number){
            if(this.$refs[`cod${number}`].value){
                this.$nextTick(function(){
                    this.$refs[`cod${number}`].focus()
                })
            }
        },

        limpiarCodigo(){
            this.value1 = '';
            this.value2 = '';
            this.value3 = '';
            this.value4 = '';
            this.value5 = '';
            this.value6 = '';
        },

        limpiarErrores(){
            this.errorCodigo = false;
            this.nuevoCodigo = false;
        },
    }
}
</script>
<style lang="scss" scoped>


.bg-view{
    height: calc(100vh - 75px); 
    background-size:cover;
    background-position: left;
    background-repeat: no-repeat;
    .box{
        width: 491px;
        height: 50vh;
        border-radius: 12px;
        box-shadow: 0px 1px 4px #00000014;
        .title{
            font-size: 37px !important;
            font-weight: 600;
        }
        .button-digits{
            width: 50px;
            height: 50px;
        }
        @media (min-width: 300px) and (max-width: 800px) {
            height: 65vh !important;
        }
    }
}


.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    left:-360px;top:-36px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}

</style>