<template>
    <section class="bg-fondo d-flex a-center j-center" style="height:calc(100vh - 75px)">
        <div class="box d-flex flex-column">
            <div id="sign-in-button" />
            <p class="title text-center pt-5">
                Verificar Código
            </p>
            <div class="row mx-0 j-center">
                <div class="col-8">
                    <p class="text-general f-15 mt-3">
                        Te enviamos un código al 
                    </p>
                    <p class="f-17">
                        <span class="f-500">+{{ datosRegistro.indicador }}</span>
                        <span class="text-green ml-2">{{ datosRegistro.telefono }}</span>
                    </p>
                </div>
            </div>
            <div class="row mx-0 mt-4 j-center">
                <div class="button-digits mx-1">
                    <el-input ref="cod1" v-model.number="value1" class="w-100" maxlength="1" @input.self="next(2)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod2" v-model.number="value2" class="w-100" maxlength="1" @input.self="next(3)" @keyup.native.delete="back(1)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod3" v-model.number="value3" class="w-100" maxlength="1" @input.self="next(4)" @keyup.native.delete="back(2)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod4" v-model.number="value4" class="w-100" maxlength="1" @input.self="next(5)" @keyup.native.delete="back(3)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod5" v-model.number="value5" class="w-100" maxlength="1" @input.self="next(6)" @keyup.native.delete="back(4)" />
                </div>
                <div class="button-digits mx-1">
                    <el-input ref="cod6" v-model.number="value6" class="w-100" maxlength="1" @keyup.native.delete="back(5)" />
                </div>
            </div>
            <div class="row mx-0 j-center my-2">
                <p class="col-8 text-general f-300 f-15">
                    ¿No te llegó nada? <span class="text-green f-500 ml-3 cr-pointer" @click="reenviarCodigo">Reenviar código</span> 
                </p>
            </div>
            <div class="row mx-0 j-center">
                <div class="col-8 px-0">
                    <div class="btn-general br-8" style="height:44px;" @click="verificarCodigo">
                        Ingresar
                    </div>
                </div>
                <div v-if="errorCodigo" class="col-8 f-14 text-general-red text-center mt-1">
                    <small>El código ingresado no es válido</small>
                </div>
                <div v-if="nuevoCodigo" class="col-8 f-14 text-green text-center mt-1">
                    <small>Se ha enviado un nuevo código</small>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-error-pass ref="modalError" />
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Registro from '~/services/registro'
import Service from '~/services/auth';
const VUEX_RUTA = 'auth'

export default {
    components: {
        modalErrorPass: () => import('./partials/modalErrorPass')
    },
    data(){
        return {
            nuevoCodigo: false,
            errorCodigo: false,
            value1: '',
            value2: '',
            value3: '',
            value4: '',
            value5: '',
            value6: '',
        }
    },
    computed: {
        ...mapGetters({
            datosRegistro: `${VUEX_RUTA}/obtenerDatosUsuarioRegistro`,
        }),
        validarDatos(){
            if(_.isEmpty(this.datosRegistro))return true;
            return false;
        },
        codigoCompleto(){
            return `${this.value1}${this.value2}${this.value3}${this.value4}${this.value5}${this.value6}`
        },
        validarCodigoCompleto(){
            return [this.value1,this.value2, this.value3, this.value4, this.value5, this.value6].every((el) => {
                return !_.isEmpty(`${el}`.trim())
            })
        },
    },
    created(){
        this.limpiarErrores();
    },
    mounted(){
        if(this.validarDatos){
            this.$router.push({name:'registro'});
        }
    },
    methods: {
        async verificarCodigo(){
            try {
                if(this.validarCodigoCompleto){
                    const data = await Registro.verificarCodigo(this.codigoCompleto)
                    if(data){
                        this.registrarUsuario();
                    }
                }
            } catch(e){
                this.errorCodigo = true;
                this.errorCatch(e)
            }
        },

        async registrarUsuario(){
            try {
                const {data} = await Service.postRegistro(this.datosRegistro);
                if(data.exito){
                    this.$store.commit('auth/ALMACENAR_TOKEN', data.token);
                    this.notificacion('Mensaje', '¡Felicidades! Te has registrado exitosamente', 'success');
                }
                this.$store.commit('home/setHomeCache',false)
                if(data.tipo_proyecto === 1){
                    //await this.$store.dispatch(`asignarTiendas/asignarTiendas`)
                    await this.$store.dispatch(`auth/consultarUsuario`)
                    this.$router.push({ name: 'home' });
                }else{
                    this.$router.push({ name: 'tienda.agregar' });
                }
            } catch(e){
                this.errorCatch(e)
            }
        },

        async reenviarCodigo(){
            try {
                this.limpiarErrores();
                const numeroCelular = `+${this.datosRegistro.indicador}${this.datosRegistro.telefono}`;
                const data = await Registro.enviarCodigoRegistro(numeroCelular);
                if(data){
                    this.nuevoCodigo = true;
                    this.limpiarCodigo();
                }
            } catch(e){
                this.errorCatch(e)
            } finally {
                setTimeout(() => {
                    this.nuevoCodigo = false;
                }, 5000)
            }
        },
        
        next(number){
            this.$nextTick(function(){
                if(this.$refs[`cod${number - 1}`].value != "" && number > 1){
                    this.$refs[`cod${number}`].focus()
                }
            })
        },

        back(number){
            if(this.$refs[`cod${number}`].value){
                this.$nextTick(function(){
                    this.$refs[`cod${number}`].focus()
                })
            }
        },

        limpiarCodigo(){
            this.value1 = '';
            this.value2 = '';
            this.value3 = '';
            this.value4 = '';
            this.value5 = '';
            this.value6 = '';
        },

        limpiarErrores(){
            this.errorCodigo = false;
            this.nuevoCodigo = false;
        },
    }
}
</script>
<style lang="scss" scoped>
.box{
    width: 491px;
    height: 50vh;
    border-radius: 12px;
    box-shadow: 0px 1px 4px #00000014;
    .title{
        font-size: 37px !important;
        font-weight: 600;
    }
    .button-digits{
        width: 50px;
        height: 50px;
    }
}

@media (min-height: 300px) and (max-height: 800px) {
    .box{
        height: 65vh !important;
    }
}

.text-general-red {
    color: red;
}
/* @media (min-height: 800px) and (max-height: 1366px) {
    .box{
        height: 38vh !important;
    }
} */
</style>