<template>
    <section class="d-flex a-center h-100 justify-content-around position-relative pr-4 bg-view" :style="`background-image:url(${getImageBg})`">
        <div class="col-6" />
        <div class="box px-xl-4 px-lg-4 px-md-4 px-sm-2 px-2 custom-scroll pb-4">
            <ValidationObserver ref="validacion">
                <p class="title-box text-center mb-3">
                    Ingresar
                </p>
                <div class="row mx-0 j-center position-relative">
                    <div class="col-12 my-2">
                        <ValidationProvider rules="required">
                            <el-input v-model="usuario" placeholder="Teléfono" class="w-100" />
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider rules="required">
                            <el-input v-model="contrasena" placeholder="Contraseña" class="w-100" show-password @keyup.enter.native="iniciarSesion" />
                        </ValidationProvider>
                    </div>
                    <div class="col-12 text-right f-400 f-15 mb-3 cr-pointer" @click="$router.push({name: 'recuperar-password'})">
                        Recuperar contraseña
                    </div>
                    <div class="col-12">
                        <div class="btn-general cr-pointer br-12" @click="iniciarSesion">
                            Ingresar
                        </div>
                    </div>
                    <!-- Mensajes de alerta -->
                    <div v-if="tipoError == 'U001'" class="position-absolute alert-message bg-white br-12">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            ¿Seguro eres tú?
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            No logro recordarte, asegúrate de <br /> escribir bien tu usuario.
                        </p>
                    </div>
                    <div v-else-if="tipoError == 'UT001'" class="position-absolute alert-message bg-white br-12">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            Espera
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Estás intentando ingresar como un administrador.
                        </p>
                    </div>
                    <div v-else-if="tipoError == 'UT002'" class="position-absolute alert-message bg-white br-12">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            Espera
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Estás intentando ingresar como un {{ $config.vendedor }}.
                        </p>
                    </div>
                    <div v-else-if="tipoError == 'C001'" class="position-absolute alert-message bg-white br-12">
                        <p class="text-red f-30 f-600 mt-3 text-center">
                            ¡Oooops!
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Parece que has ingresado mal tu <br /> contraseña, vuelve a intentarlo.
                        </p>
                    </div>
                </div>
                <div class="row mx-0 px-3 mt-5 mb-3 a-center text-gris2">
                    <div class="col pl-0">
                        <hr class="border" />
                    </div>
                    O ingresa con
                    <div class="col pr-0">
                        <hr class="border" />
                    </div>
                </div>
                <div class="row mx-0 j-center">
                    <!--
                    <div class="col-auto px-2 mb-2">
                        <div class="btn-action-facebook cr-pointer" @click="startConnectionProvider">
                            <i class="icon-facebook-circled f-22" />
                        </div>
                    </div>
                    -->
                    <div class="col-auto px-2 mb-2">
                        <div class="btn-action-google cr-pointer" @click="startConnectionProvider('google')">
                            <i class="icon-google f-22" />
                        </div>
                    </div>
                    <div class="col-auto px-2 mb-2">
                        <div class="btn-action-mobile cr-pointer" @click="$router.push({name: 'ingreso.celular'})">
                            <i class="icon-cellphone f-22" />
                        </div>
                    </div>
                    <!-- <div class="col-auto px-2">
                        <div class="btn-action-mail cr-pointer" @click="$router.push({name: 'ingreso.correo'})">
                            <i class="icon-email f-18" />
                        </div>
                    </div> -->
                </div>
            </ValidationObserver>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
const VUEX_RUTA = 'auth'
// Importante para autenticación por medio de firebase
import firebase from '~/library/firebase'; // No quitar
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// ----

export default {
    layout: 'auth',
    data(){
        return{
            usuario: '',
            contrasena: '',
            indice: 0,
        }
    },

    computed: {
        ...mapGetters({
            error: `${VUEX_RUTA}/obtenerError`,
            token: `${VUEX_RUTA}/obtenerToken`,
            usuarioLogueado: `${VUEX_RUTA}/obtenerUsuario`,
            imgCliente :'x_parametros/imgCliente',
        }),

        imgLogin(){
            return this.imgCliente.login;
        },

        existenErrores(){
            return !_.isEmpty(this.error)
        },

        existenErroresCamposVacios(){
            return !_.isEmpty(this.error.errors)
        },

        tipoError(){
            if(!_.isEmpty(this.error)){
                return this.error.codigo;
            }
            return "";
        },
    },

    mounted(){
        setInterval(() => {
            let cantidad = this.imgLogin.length - 1
            this.indice++;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);
    },

    methods: {
        ...mapActions({
            intentarIniciarSesion: `${VUEX_RUTA}/intentarIniciarSesion`,
            iniciarSesionGoogle: `${VUEX_RUTA}/iniciarSesionGoogle`,
        }),

        ...mapMutations({
            almacenarError: `${VUEX_RUTA}/ALMACENAR_ERROR`,
            removerErrores: `${VUEX_RUTA}/REMOVER_ERRORES`,
        }),

        async iniciarSesion(){
            const valid = await this.$refs.validacion.validate()
            if(!valid)return;
            
            const params = {
                usuario: this.usuario,
                contrasena: this.contrasena,
            };

            this.intentarIniciarSesion(params)
                .catch(this.almacenarError)
                .finally(() => {
                    this.validarRedireccionarInicioSesion();
                });
        },

        async startConnectionProvider(typeProvider){
            // Conectamos por medio de Firebase al proveedor indicado para autenticación
            if(typeProvider == 'google'){
                const provider = new GoogleAuthProvider();
                // Agregamos el permiso email al scope de la respuesta del proveedor (En este caso google)
                provider.addScope('email');
                // iniciamos con la conexión
                this.startConnectionGoogle(provider);
            }
        },

        async startConnectionGoogle(provider){
            const auth = getAuth();
            await signInWithPopup(auth, provider)
                .then((result) => {
                    /*
                    - result: Retornara la información que tiene google del usuario, como correo, nombre etc...
                    - credential: Retornara la información de acceso a google, como el token de acceso etc...
                    */
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const payload = {
                        provider: 'google',
                        token: credential.accessToken,
                        tipo: 3
                    };

                    this.iniciarSesionGoogle(payload)
                        .catch(this.almacenarError)
                        .finally(() => {
                            this.validarRedireccionarInicioSesion();
                        });
                }).catch((error) => {
                    // error: Retornara información acerca de los errores enviado por google
                    this.notificacion('No es posible', 'Ocurrio un error al intentar conectar con google', 'warning');
                });
        },

        validarRedireccionarInicioSesion(){
            if (this.existenErrores || this.existenErroresCamposVacios || _.isEmpty(this.token)) return;
            this.$store.commit('home/setHomeCache',false)
            if(!this.usuarioLogueado.tienda){
                return this.$router.push({ name: 'tienda.agregar' });
            }
            this.$router.push({ name: 'home' });
        },
        openWhastApp(){
            const numero = '+573214432441'
            const mensaje = `Hola! Tengo problemas para el Ingreso en ${this.$config.proyecto}`
            window.open(`https://api.whatsapp.com/send?phone=${numero}&text=` + encodeURIComponent(mensaje));
        },
        getImageBg(){
            if(this.imgLogin.length) return this.imgLogin[this.indice]
        }
    },
}
</script>
<style lang="scss" scoped>

.bg-view{
    background-size:cover;
    background-repeat: no-repeat;
    transition: all 1s ease-out !important;
}


.image-ingreso{
    max-height:100%;
    width:100%;
    object-fit:cover;
}
.box{
    width: 423px;
    height: auto;
    box-shadow: 0px 1px 4px #00000014;
    border-radius: 12px;
    .title-box{
        font-size: 37px;
        color: #000000;
        font-weight: 600;
    }
}

.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    left:-360px;top:-36px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}

// Media Queries

@media (min-width: 300px) and (max-width: 1366px) {
    section{
        background-image: inherit !important;
    }
    .col-6{
        display: none !important;
    }
    
}

@media (min-width: 300px) and (max-width: 648px) {
    section{
        padding-right: 0px !important;
        .box{
            width: 100%;
            height: calc(100vh - 96px);
            border-radius: inherit !important;
            &::-webkit-scrollbar-track{ 
                height: 6px; 
                width:0px; 
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); 
                background-color:transparent; 
                border-radius: 5px; 
            }
            &::-webkit-scrollbar{ 
                background-color:transparent; 
                width:0px; 
                height: 6px; 
                border-radius: 5px; 
            }
            &::-webkit-scrollbar-thumb{ 
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); 
                background-color:transparent; 
                border-radius: 5px; 
            }
        }
    }
}
</style>
