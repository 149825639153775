<template>
    <section class="d-flex a-center justify-content-around position-relative pr-4 bg-view">
        <div class="col-5" />
        <div class="box px-4 custom-scroll pb-4">
            <p class="title-box text-center mb-3 lh-40">
                Ingresar con tu <br /> correo electrónico
            </p>
            <div class="row mx-0 j-center position-relative">
                <div class="col-12 text-general f-15 my-2 mt-4">
                    Enviaremos un código de verificación al correo electrónico.
                </div>
                <div class="col-12 my-2">
                    <el-input v-model="correo" placeholder="Correo electrónico" class="w-100" />
                </div>
                <div class="col-12 my-2">
                    <div class="btn-general cr-pointer br-12" @click="enviarCodigo">
                        Recibir Código
                    </div>
                </div>
                <!-- Mensajes de alerta -->
                <div v-if="tipoError == 'U001'" class="position-absolute alert-message bg-white br-12">
                    <p class="text-red f-30 f-600 mt-3 text-center">
                        ¿Seguro eres tú?
                    </p>
                    <p class="text-general f-15 mt-1 f-500 text-center">
                        No logro recordarte, asegúrate de <br /> escribir bien tu correo electrónico.
                    </p>
                </div>
                <div v-else-if="tipoError == 'UT001'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                    <p class="text-red f-30 f-600 mt-3 text-center">
                        Espera
                    </p>
                    <p class="text-general f-15 mt-1 f-500 text-center">
                        Estás intentando ingresar como un administrador.
                    </p>
                    <p class="text-green cr-pointer f-15 mt-1 f-500 text-center">
                        Ir a la aplicación de administrador
                    </p>
                </div>
                <div v-else-if="tipoError == 'UT002'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                    <p class="text-red f-30 f-600 mt-3 text-center">
                        Espera
                    </p>
                    <p class="text-general f-15 mt-1 f-500 text-center">
                        Estás intentando ingresar como un {{ $config.vendedor }}.
                    </p>
                    <p class="text-green cr-pointer f-15 mt-1 f-500 text-center">
                        Ir a la aplicación de {{ $config.vendedor }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
const VUEX_RUTA = 'auth'
export default {
    layout: 'auth',
    data(){
        return {
            correo: ''
        }
    },
    computed: {
        ...mapGetters({
            error: `${VUEX_RUTA}/obtenerError`,
        }),
        existenErrores(){
            return !_.isEmpty(this.error)
        },
        tipoError(){
            if(!_.isEmpty(this.error)){
                return this.error.codigo;
            }
            return "";
        },
    },
    methods: {
        ...mapActions({
            enviarCodigoCorreo: `${VUEX_RUTA}/enviarCodigoCorreo`,
        }),
        ...mapMutations({
            almacenarError: `${VUEX_RUTA}/ALMACENAR_ERROR`,
        }),
        async enviarCodigo(){
            try {
                if(_.isEmpty(this.correo))return;
                const params = {
                    correo: this.correo
                };
                this.enviarCodigoCorreo(params)
                    .catch(this.almacenarError)
                    .finally(() => {
                        this.validarRedireccionar();
                    });
            } catch(e){
                // error
            }
        },
        validarRedireccionar(){
            if (this.existenErrores) return;
            this.$router.push({name:'verificar-codigo-registro'});
        },
    },
}
</script>
<style lang="scss" scoped>

.bg-view{
    height: calc(100vh - 75px); 
    background-image: url('/img/login/img_cliente.png');
    background-size:cover;
}

.box{
    width: 423px;
    height: 386px;
    box-shadow: 0px 1px 4px #00000014;
    border-radius: 12px;
    .title-box{
        font-size: 37px;
        color: #000000;
        font-weight: 600;
    }
}

.lh-40{
    line-height: 40px;
}

.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    left:-360px;top:-36px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}

// Media Queries

@media (min-width: 300px) and (max-width: 1204px) {
    section{
        background-image: inherit !important;
    }
    .col-5{
        display: none !important;
    }
}

@media (min-width: 300px) and (max-width: 780px) {
    .col-auto{
        display: none !important;
    }
}

@media (min-width: 300px) and (max-width: 648px) {
    section{
        padding-right: 0px !important;
        .box{
            width: 100%;
            height: 88vh;
            border-radius: inherit !important;
            &::-webkit-scrollbar-track{ 
                height: 6px; 
                width:0px; 
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); 
                background-color:transparent; 
                border-radius: 5px; 
            }
            &::-webkit-scrollbar{ 
                background-color:transparent; 
                width:0px; 
                height: 6px; 
                border-radius: 5px; 
            }
            &::-webkit-scrollbar-thumb{ 
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); 
                background-color:transparent; 
                border-radius: 5px; 
            }
        }
    }
}
</style>
