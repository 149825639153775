<template>
    <section class="d-flex a-center justify-content-xl-end justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-center position-relative pr-xl-4 pr-lg-0 pr-md-0 pr-sm-0 pr-0 bg-view" :style="`background-image: url(${imgRegistro[indice]});`">
        <ValidationObserver ref="validacion">
            <div id="sign-in-button" />
            <div class="col-6" />
            <div class="box px-4 overflow-auto custom-scroll pb-4">
                <p class="title-box text-center my-3">
                    Regístrate
                </p>
                <div class="row mx-0 j-center">
                    <div class="col-auto text-center">
                        <img :src="avatar" width="104" height="104" class="rounded-circle obj-cover cr-pointer" @click="seleccionarAvatar" />
                        <p class="text-center text-general f-14 mt-2">Selecciona tu avatar</p>
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0">
                    <div class="col-12 px-3">
                        <p class="text-general f-12 pl-3">Nombre</p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:18" name="Nombres">
                            <el-input v-model="model.nombres" placeholder="Nombre..." size="small" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <!-- <div class="row mx-0 py-2" /> -->
                <div v-if="false" class="row mx-0">
                    <div class="col-12 px-3">
                        <p class="text-general f-12 pl-3">Apellido</p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:18" name="Apellidos">
                            <el-input v-model="model.apellidos" placeholder="Ej: Perez" size="small" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 py-2" />
                <div v-if="tipoIdentificacion" class="row mx-0">
                    <div class="col-12 px-3">
                        <p class="text-general f-12 pl-3">{{ tipoIdentificacion }}</p>
                        <ValidationProvider v-slot="{errors}" :rules="{max:20,required:nameProyecto === 'MXM'}" :name="tipoIdentificacion">
                            <el-input v-model="model.identificacion" :placeholder="tipoIdentificacion" maxlength="20" size="small" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 py-2" />
                <div class="row mx-0 mt-2">
                    <div class="col-12">
                        <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Telefono">
                            <el-input v-model="model.telefono" :placeholder="`${tipo == 1 ? 'Número de telefono' : 'Escriba tu correo electronico'}`" size="small" class="w-100 br-10" :class="tipo == 1 ? 'options-number' : 'options-email'">
                                <div v-if="tipo == 1" slot="prepend" class="d-middle">
                                    <img :src="bandera" class="br-2 obj-cover" width="35" height="20" />
                                    <el-select v-model="model.indicador" placeholder="+ 57" @change="seleccionarBandera">
                                        <el-option
                                        v-for="item in paises"
                                        :key="item.indicativo"
                                        :label="'+'+item.indicativo"
                                        :value="item.indicativo"
                                        />
                                    </el-select>
                                </div>
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 py-2" />
                <div class="btn-ubicacion mx-3 d-flex a-center px-2 mt-2 br-12 cr-pointer" @click="seleccionarUbicacion">
                    <i class="icon-map-pin text-gris2 f-18" />
                    <p class="col px-2 f-14 text-general f-300">
                        {{ ubicacion ? ubicacion : 'Seleccionar ciudad' }}
                    </p>
                    <i class="icon-down-open text-gris f-14" />
                </div>
                <div v-if="validCiudad" class="row mx-0">
                    <p class="col-auto px-2 text-danger w-100 f-11 ml-3">
                        El campo ciudad es obligatorio
                    </p>
                </div>
                <div class="row mx-0 py-2" />
                <div class="row mx-0 j-center">
                    <el-checkbox v-model="model.condiciones" class="size-medium check-dark" @change="aceptarTerminos($event)" />
                    <p class="col-auto f-13">
                        Aceptar <span class="text-green cr-pointer" @click="irCondiciones">Términos & Condiciones</span>  y <br /> <span class="text-green cr-pointer" @click="irPoliticas">Políticas de tratamiento de datos personales</span>
                    </p>
                    <p v-if="validCodiciones" class="col-auto text-danger w-100 f-11 ml-5">
                        Debe Aceptar Términos & Condiciones y Políticas de tratamiento de datos personales
                    </p>
                </div>
                <div class="btn-general text-white mx-3 my-3" @click="validarUsuario">
                    Recibir código
                </div>
                <div class="btn-register f-400 mx-3 br-12 d-flex a-center j-center f-16 cr-pointer" @click="irIngresar">
                    <span class="text-general">¿Ya estás registrado?</span> <span class="text-green ml-2">Ingresar</span>
                </div>
            </div>
            <div class="col-auto px-xl-4 pr-lg-4 pr-md-2 pr-sm-0 pr-0" />
        </ValidationObserver>

        <!-- Partials -->
        <modal-ubicacion ref="modalUbicacion" @changeUbicacion="mapearUbicacion" />
        <modal-select-avatar ref="modalSelect" @changeAvatar="mapearAvatar" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Registro from '~/services/registro'
import Service from '~/services/auth';

export default {
    layout: 'auth',
    components: {
        modalUbicacion: () => import('./partials/modalUbicacionRegistro'),
        modalSelectAvatar: () => import('./partials/modalSelectAvatarRegistro')
    },
    data(){
        return{
            validCodiciones: false,
            validCiudad: false,
            model: {
                nombres: null,
                apellidos: null,
                condiciones: false,
                ciudad: null,
                idAvatar: null,
                telefono: null,
                indicador: 57,
                origen: 2,
                identificacion: null,
            },
            paises: [],
            input: '',
            value: '',
            tipo: 1,
            bandera: null,
            ubicacion: null,
            avatar: '/img/no-imagenes/no-imagen-avatar.svg',
            indice: 0,
            tipoIdentificacion: null,
        }
    },
    computed: {
        ...mapGetters({
            imgCliente :'x_parametros/imgCliente',
            tiendaInvitado: 'invitado/tienda',
        }),
        imgRegistro(){
            return this.imgCliente.registro;
        },
        nameProyecto(){
            return process.env.VUE_APP_NAME
        }
    },

    mounted(){
        if(!_.isEmpty(this.tiendaInvitado)){
            this.ubicacion = this.tiendaInvitado.ciudad_seleccionada + "," + this.tiendaInvitado.pais;
            this.model.ciudad = this.tiendaInvitado.id_ciudad_leechero;
            this.validCiudad = false;
        }
        this.getPaises();
        this.getTipoIdentificacion();

        setInterval(() => {
            let cantidad = this.imgRegistro.length - 1
            this.indice++;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);
    },

    methods: {
        async getPaises(){
            try {
                const { data } = await Service.getPaises();
                this.paises = data.paises;
                this.bandera = data.paises.find((item) => parseInt(item.indicativo) === 57).bandera;
            } catch(e){
                this.errorCatch(e)
            }
        },

        async getTipoIdentificacion(){
            try {
                const { data } = await Service.getTipoIdentificacion();
                this.tipoIdentificacion = data.tipoIdentificacion;
            } catch(e){
                this.errorCatch(e);
            }
        },

        async validarUsuario(){	
            try {
                const valid = await this.$refs.validacion.validate()
        
                if(this.model.ciudad == null || this.model.ciudad == ''){
                    this.validCiudad = true;
                } else {
                    this.validCiudad = false;
                }

                if(!this.model.condiciones){
                    this.validCodiciones = true;
                } else {
                    this.validCodiciones = false;
                }

                if(!valid || this.validCiudad || this.validCodiciones)return;

                const params = {
                    telefono: this.model.telefono,
                    correo: '',
                };
                const { data } = await Service.existeUsuario(params)
                if(data.existe){
                    return this.notificacion('Mensaje', 'Ya existe un usuario registrado con este número de teléfono', 'warning');
                }
                this.enviarCodigo();
            } catch(e){
                this.errorCatch(e)
            }
        },
        async enviarCodigo(){
            try {
                const numeroCelular = `+${this.model.indicador}${this.model.telefono}`;
                const data = await Registro.enviarCodigoRegistro(numeroCelular);
                if(data){
                    this.$store.commit('auth/ALMANECAR_DATOS_USUARIO_REGISTRO', this.model);
                    this.$router.push({name:'verificar-codigo-registro'});
                }else{
                    await Service.postRegistro(this.model);
                }
                console.log(data);
            } catch(error){
                this.errorCatch(error)
            }
        },
        async aceptarTerminos(event){
            if(event){
                this.validCodiciones = false;
            } else {
                this.validCodiciones = true;
            }
        },
        seleccionarUbicacion(){
            this.$refs.modalUbicacion.toggle();
        },
        mapearUbicacion(data){
            this.ubicacion = data.mostrar;
            this.model.ciudad = data.idCiudad;
            this.validCiudad = false;
        },
        mapearAvatar(data){
            this.model.idAvatar = data.id;
            this.avatar = data.imagen;
        },
        irIngresar(){
            this.$router.push({name:'ingreso.celular'});
        },
        seleccionarBandera(){
            this.bandera = this.paises.find((item) => item.indicativo === this.model.indicador).bandera;
        },
        seleccionarAvatar(){
            this.$refs.modalSelect.toggle();
        },
        irCondiciones(){
            const goRoute = this.$router.resolve({ name: 'terminos-condiciones-cliente'});
            window.open(goRoute.href, '_blank');
        },
        irPoliticas(){
            const goRoute = this.$router.resolve({ name: 'politicas-privacidad-cliente'});
            window.open(goRoute.href, '_blank');
        },
        openWhastApp(){
            const numero = '+573214432441'
            const mensaje = `Hola! Tengo problemas para el registro en ${this.$config.proyecto}`
            window.open(`https://api.whatsapp.com/send?phone=${numero}&text=` + encodeURIComponent(mensaje));
        },
    }
}
</script>
<style lang="scss" scoped>

.bg-view{
    height: calc(100vh - 75px);
    background-size:cover;
    background-repeat: no-repeat;
    transition: all 1s ease-out !important;
}

.box{
    width: 423px;
    height: calc(100vh - 209px);
    box-shadow: 0px 1px 4px #00000014;
    border-radius: 12px;
    .title-box{
        font-size: 37px;
        color: #000000;
        font-weight: 600;
    }
    .btn-ubicacion{
        border: 1px solid #DBDBDB;
        border-radius: 6px;
        background-color: #FFFFFF;
        height: 37px;
    }
    .btn-register{
        border: 1px solid #DBDBDB;
        border-radius: 6px;
        background-color: #FFFFFF;
        height: 44px;
    }
}

.codiciones {
    border: 1px solid red;
    border-radius: 10px;
}


// Media Queries

@media (min-width: 300px) and (max-width: 1204px) {
    section{
        background-image: inherit !important;
    }
    .col-6{
        display: none !important;
    }
}

@media (min-width: 300px) and (max-width: 780px) {
    .space-image{
        display: none !important;
    }
}

@media (min-width: 300px) and (max-width: 648px) {
    section{
        padding-right: 0px !important;
        .box{
            width: 100%;
            height: 88vh;
            border-radius: inherit !important;
            &::-webkit-scrollbar-track{ 
                height: 6px; 
                width:0px; 
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); 
                background-color:transparent; 
                border-radius: 5px; 
            }
            &::-webkit-scrollbar{ 
                background-color:transparent; 
                width:0px; 
                height: 6px; 
                border-radius: 5px; 
            }
            &::-webkit-scrollbar-thumb{ 
                -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.0); 
                background-color:transparent; 
                border-radius: 5px; 
            }
        }
    }
}
</style>