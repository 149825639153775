<template>
    <section class="bg-fondo d-flex a-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center bg-view" :style="`background-image:url(${imgContrasena[indice]})`" style="">
        <ValidationObserver ref="validacion">
            <div class="box d-flex pb-5 flex-column mr-xl-5 mr-lg-5 mr-md-0 mr-sm-0 mr-0">
                <div id="sign-in-button" />
                <p class="title text-center pt-5">
                    Recuperar <br /> contraseña
                </p>
                <div class="row mx-0 j-center mt-4">
                    <div class="col-10 px-0">
                        <div v-if="error" class="position-absolute alert-message bg-white br-12">
                            <p class="text-red f-30 f-600 mt-3 text-center">
                                ¿Seguro eres tú?
                            </p>
                            <p class="text-general f-15 mt-1 f-500 text-center">
                                No logro recordarte, asegúrate de <br /> escribir bien tu usuario.
                            </p>
                        </div>
                        <p class="text-general mt-3 f-400">
                            Introduce el número de  telefono para validar tu cuenta.
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Número de celular">
                            <el-input v-model="model.telefono" placeholder="Número de celular" class="mt-4 w-100 br-10 options-number">
                                <div slot="prepend" class="d-middle">
                                    <img :src="bandera" class="br-2" width="35" height="20" />
                                    <el-select v-model="model.indicativo" placeholder="+ 57" @change="seleccionarBandera">
                                        <el-option
                                        v-for="item in paises"
                                        :key="item.indicativo"
                                        :label="'+'+item.indicativo"
                                        :value="item.indicativo"
                                        />
                                    </el-select>
                                </div>
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                        <div class="btn-general text-white f-16 br-10 mt-3" style="height:44px;" @click="validarUsuario">
                            Recibir código
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <div class="col-auto px-xl-4 px-lg-4 px-md-2 px-sm-2 px-0" />
        <!-- Partials -->
        <modal-error-pass ref="modalError" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Service from '~/services/auth';
import Registro from '~/services/registro'

export default {
    components: {
        modalErrorPass: () => import('./partials/modalErrorPass')
    },
    data(){
        return {
            paises: [],
            bandera: null,
            error: false,
            indice: 0,
            model: {
                telefono: null,
                indicativo: 57,
            },
        }
    },
    computed: {
        ...mapGetters({
            imgCliente :'x_parametros/imgCliente',
        }),
        imgContrasena(){
            return this.imgCliente.contrasena;
        }
    },
    mounted(){
        setInterval(() => {
            let cantidad = this.imgContrasena.length - 1
            this.indice++;
            if(this.indice > cantidad ){
                this.indice = 0;
            }
        }, 5000);
        this.getPaises();
    },
    methods: {
        async getPaises(){
            try {
                const { data } = await Service.getPaises();
                this.paises = data.paises;
                this.bandera = data.paises.find((item) => parseInt(item.indicativo) === 57).bandera;
            } catch(e){
                this.errorCatch(e)
            }
        },

        async validarUsuario(){
            try {
                this.error = false;
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const {data} = await Service.existeUsuario(this.model)
                if(!data.existe){
                    return this.error = true;
                }
                this.enviarCodigo();
            }catch(e){
                this.errorCatch(e)
            }
        },

        async enviarCodigo(){
            try {
                const numeroCelular = `+${this.model.indicativo}${this.model.telefono}`;
                const data = await Registro.enviarCodigoRegistro(numeroCelular);
                if(!data){
                    return this.notificacion('Mensaje', 'Ocurrio un error al intentar enviar el código', 'warning');
                }
                this.$store.commit('auth/ALMANECAR_DATOS_USUARIO_REGISTRO', this.model);
                this.verificarCodigo();
            } catch(e){
                this.errorCatch(e)
            }
        },

        verificarCodigo(){
            this.$router.push({name: 'verificar-codigo'})
        },

        seleccionarBandera(){
            this.bandera = this.paises.find((item) => item.indicativo === this.model.indicativo).bandera;
        },
    }
}
</script>
<style lang="scss" scoped>

.bg-view{
    height: calc(100vh - 75px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1s ease-out !important;
    @media screen and (min-width: 300px) and (max-width: 980px) {
        background-image: initial !important;
    }
}

.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    left:-360px;top:-36px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}

.box{
    width: 420px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0px 1px 4px #00000014;
    .title{
        font-size: 37px !important;
        font-weight: 600;
        line-height: 38px;
    }
    @media screen and (min-width: 300px) and (max-width: 800px) {
        height: 65vh !important;
    }
    @media screen and (min-width: 300px) and (max-width: 425px) {
        height: calc(100vh - 96px) !important;
        border-radius: 0px !important;
        .text-general.mt-3.f-400{
            text-align: center;
        }
    }
}

</style>